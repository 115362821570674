import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/Layout"

const Historia = () => {
  return (
    <Layout
      title={"Historia"}
      image={
        <StaticImage
          src={"../assets/images/history.jpg"}
          alt={"budynek"}
          placeholder={"blurred"}
          className={"site-banner"}
          style={{ position: "absolute" }}
        />
      }
    >
      <div className="d-flex flex-column flex-lg-row gap-5 align-items-center">
        <div>
          Początki swej działalności nasza firma wywodzi z Zespołu Inspektorów
          Nadzoru w Toruniu, powstałego w roku 1966 przy bydgoskim
          "Inwestprojekcie", obsługującym inwestycje budownictwa mieszkaniowego
          i towarzyszącego. W roku 1976 Zespół przekształcił się w Zakład
          Obsługi Inwestycyjnej, pełniący rolę inwestora zastępczego dla
          budownictwa mieszkaniowego i towarzyszącego dla Spółdzielni
          Mieszkaniowych zlokalizowanych na terenie nowo powstałego województwa
          toruńskiego. Od roku 1986 działalność została rozszerzona o usługi
          projektowe w zakresie wielobranżowym, co znalazło odbicie w nazwie
          zakładu, zmienionej na Zakład Projektowania i Usług Inwestycyjnych
          "Inwestprojekt" w Toruniu. W grudniu 1993 roku dwa kolejne Walne
          Zgromadzenia podejmują uchwałę o postawieniu Spółdzielni w stan
          likwidacji.
        </div>
      </div>
      <div className="d-flex flex-column flex-lg-row gap-5 align-items-center">
        <div>
          W styczniu 1994 roku pracownicy "Inwestprojektu" Spółdzielni Pracy w
          Toruniu zawiązali spółkę z ograniczoną odpowiedzialnością, nadając jej
          nazwę "Inwestprojekt" Autorskie Biuro Projektów Budownictwa i Usług w
          Toruniu, która została wpisana do Krajowego Rejestru Sądowego pod nr
          KRS:0000202945 i posiada REGON 870223717 oraz NIP 879-00-15-720.
          Spółka stanowi własność Zdzisława A. Krügera, Andrzeja Krügera i
          Katarzyny Krüger.
        </div>
      </div>
    </Layout>
  )
}

export default Historia
